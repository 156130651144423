import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo, useState, useEffect } from 'react'
import Layout from '../../components/layout'
import { flatten } from '../../utils/data'
import ImageHero from '../../components/image-hero'
import tw, { css, styled } from 'twin.macro'
import Button from '../../components/button'
import Heading from '../../components/heading'
import LogoSlider from '../../components/logo-slider'
import StatisticsScroller from '../../components/statistics-scroller'
import Text from '../../components/text'
import { global } from '../../styles/global'
import ContactCard from '../../components/contact-card'
import ArticleCarousel from '../../components/article-carousel'
import Gradient from '../../components/gradient'
import HoverCardCarousel from '../../components/hover-card-carousel'
import CardCarousel from '../../components/card-carousel'
import FAQ from '../../components/faq'
import kebabCase from 'lodash/kebabCase'
import Image from '../../components/image'
import { xs, sm, md } from '../../utils/breakpoints'

const IndexPage = ({ data: { index }, location }) => {
  const page = useMemo(
    () =>
      flatten(index || {}, [
        'hero',
        'statisticsCta',
        'logosHeader',
        'contactHeader',
        'faqHeader',
        'brokersHeader',
        'featuresHeader',
        'newsHeader',
      ]),
    [index]
  )

  const links = useMemo(
    () =>
      get(page, 'faqQuestion', []).map((p) => ({
        label: p.title,
        link: `/${kebabCase(p.identifier)}`,
      })),
    [page]
  )

  const featureCards = useMemo(
    () =>
      (get(page, 'features') || []).map((feature) => {
        feature.points = feature.descriptionNode.childMarkdownRemark.html
          .replace(/(<p>)|(<\/p>)/g, '')
          .split('<br>\n')
        return feature
      }),
    [page]
  )

  useEffect(() => {
    const hashtag = window.location.hash
    if (hashtag) {
      const currentURL = window.location.href
      const newURL = currentURL.split('#')[0]
      window.history.replaceState('', 'lunch', newURL)
    }
  })

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition={true}>
      <section id="about" css={tw`py-20 relative lg:(pt-0 pb-0)`}>
        <ImageHero
          image={get(page, 'hero.image')}
          imageMobile={get(page, 'hero.imageMobile')}
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          subtitle={get(page, 'hero.subtitleNode')}
          gradStyle={[
          css`
            ${xs} {
              bottom: calc(64px + (16 - 64) * ((100vw - 320px) / 93));
            }
            ${sm} {
              bottom: calc(64px + (32 - 64) * ((100vw - 414px) / 303));
            }
            ${md} {
              bottom: 64px;
            }
          `
          ]}
          hideCaret
          withGradient
        />
      </section>
      <section id="logos" css={tw`py-24 bg-white lg:(pt-28 pb-24)`}>
        <div css={[tw`overflow-hidden lg:w-full`]}>
          <Heading
            content={get(page, 'logosHeader.titleNode')}
            headingType="h2"
            style={tw`text-center text-primary-500 text-3xl`}
          />
          <Heading
            content={get(page, 'logosHeader.subtitleNode')}
            headingType="h3"
            style={tw`text-center text-primary-500 text-3xl`}
          />
        </div>
        <LogoSlider logos={get(page, 'logos')} style={tw`mt-12 lg:mt-16`} />
      </section>
      <section id="whoweare" css={tw`relative bg-grey-light `}>
        <Gradient
          style={tw`place-content-end pt-8 h-8 lg:(pt-26 h-14 w-full)`}
          firstColumnStyle={tw`bg-primary-500 h-8 w-8 lg:(h-14  w-14)`}
          lastColumnStyle={tw`h-8 w-56 from-secondary-500 via-secondary-500 to-secondary-gradient lg:(h-14 w-96)`}
        />
        <div css={[global`layout.container`, global`layout.grid`, tw`h-full`]}>
          <div css={tw`col-span-4 pt-20 pb-12 md:col-span-8 lg:(col-span-5 pt-14 pb-40) xl:col-span-6`}>
            <div css={tw`flex flex-col items-start top-32`}>
              <Heading
                content={get(page, 'statisticsCta.titleNode')}
                headingType="h1"
                //style={tw`font-light`}
              />
              <Text content={get(page, 'statisticsCta.descriptionNode')} style={tw`text-lg mt-6`} />
              {/* <Button
                label={get(page, 'statisticsCta.callToAction.label')}
                link={get(page, 'statisticsCta.callToAction.link')}
                disabled={get(page, 'statisticsCta.callToAction.disabled')}
                type="primary"
                size="sm"
                theme="yellow-yellow"
                style={tw`mt-8 lg:mt-10`}
              /> */}
            </div>
            <Gradient
              style={tw`place-content-start mt-24 absolute left-0 bottom-0 h-8 lg:(h-14 w-full )`}
              firstColumnStyle={tw`self-end from-primary-gradient to-primary-500 h-8 w-44 lg:(h-14  w-109 )`}
              lastColumnStyle={tw` self-end bg-accent-500 h-8 w-8 lg:(h-14 w-14)`}
            />
          </div>
          <div
            css={tw`col-span-4 md:col-span-8 lg:(col-start-7 col-end-13 -mr-container-lg)
            xl:(col-start-9 col-end-17 -mr-container-xl)
            xxl:-mr-container-xxl`}
          >
            <StatisticsScroller
              statistics={get(page, 'statistics')}
              style={tw`pb-20 lg:(pt-14 pb-30)`}
            />
          </div>
        </div>
      </section>
      <section id="products" css={tw`relative overflow-x-hidden`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`py-20 lg:py-40`]}>
          <div css={tw`col-span-4 md:col-span-8 lg:(self-center mb-24 col-span-6) xl:col-span-8`}>
            <Heading
              content={get(page, 'brokersHeader.titleNode')}
              headingType="h2"
              style={css`
                strong {
                  ${tw`font-medium`}
                }
              `}
            />
          </div>
          <div
            css={tw`col-span-4 mt-6 md:col-span-8
            lg:(self-center col-span-6 mt-0 mb-24) xl:(col-start-10 col-span-6)`}
          >
            <Heading
              headingType="h5"
              content={get(page, 'brokersHeader.descriptionNode')}
              style={tw`font-normal`}
            />
          </div>
          <div
            css={tw`col-span-4 mt-7 md:col-span-8
            lg:(col-start-1 col-end-13 mt-0) xl:(col-end-17)`}
          >
            <HoverCardCarousel cards={get(page, 'brokers')} sliderLabel={get(page, 'sliderLabel')}/>
          </div>
        </div>
      </section>
      <section id="features" css={tw`bg-primary-50 overflow-x-hidden`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`py-20 lg:py-40`]}>
        <div
            css={tw`col-span-4 mb-8 md:col-span-8 lg:(col-span-12 self-center mb-24) xl:(col-span-7)`}
          >
            <Heading
              content={get(page, 'featuresHeader.titleNode')}
              headingType="h2"
              style={css`
                strong {
                  ${tw`font-medium`}
                }
              `}
            />
          </div>
          <div css={tw`col-span-4 md:col-span-8 lg:(col-start-1 col-end-13) xl:col-end-17`}>
            <CardCarousel cards={featureCards} swipeLabel={get(page, 'swipeLabel')}/>
          </div>
          <div css={tw`hidden lg:(block col-span-12 self-center mb-16 mt-32) xl:col-span-10`}>
            <Heading
              headingType="h4"
              content={get(page, 'reachOutUsNode')}
              style={[
                css`
                  ${tw`font-normal`}
                  a {
                    ${tw`underline`}
                  }
                `,
              ]}
            />
          </div>
        </div>
      </section>
      <section id="news" css={tw`bg-white relative py-20 overflow-hidden lg:(py-40)`}>
        <div css={[global`layout.container`, tw`lg:(flex flex-row items-center justify-between)`]}>
          <Heading
            content={get(page, 'newsHeader.titleNode')}
            headingType="h1"
            style={tw`text-primary-500`}
          />
          {get(page, 'showAllNewsCta') && (
          <Button
            type="primary"
            size="sm"
            style={tw`hidden opacity-50 lg:flex`}
            theme="transparent-transparent"
            label={get(page, 'newsLink.label')}
            link={get(page, 'newsLink.link')}
          />
          )}        
          </div>
        <ArticleCarousel cards={get(page, 'news')} style={tw`mt-16  lg:(mt-20 mb-0)`} />
        {get(page, 'showAllNewsCta') && (
        <div css={[global`layout.container`, tw`mb-20 mt-8`]}>
          <Button
            type="primary"
            size="sm"
            style={css`
              ${tw`opacity-50 lg:hidden`}
              span {
                ${tw`ml-0`}
              }
            `}
            theme="transparent-transparent"
            label={get(page, 'newsLink.label')}
            link={get(page, 'newsLink.link')}
          />
        </div>
        )}      
      </section>
      <section id="faq" css={[tw`relative bg-grey-light py-20 lg:py-40 `]}>
      <div css={[global`layout.container`, global`layout.grid`]}>
            <Heading
              content={get(page, 'faqHeader.titleNode')}
              headingType="h2"
              style={tw`col-span-4 text-primary-500 md:col-span-8 lg:(col-span-12 self-center) xl:col-span-10`}
            />
            </div>
            <div
              css={tw`flex flex-row col-span-4 space-y-8 mt-12 md:col-span-8 lg:(col-span-12 mt-16)`}
            >
              <FAQ 
                links={links}                 
                style={tw`w-full`}
                subNavStyle={tw`bg-white`}
                selectBackground={'bg-white'}
                offices={get(page, 'faqQuestion')}
                buttonLabel={get(page, 'faqButtonLabel')}
                numQuestions={3}
              />
            </div>        
      </section>   
      <section id="contactus" css={tw`relative pt-24 pb-22 bg-primary-50 lg:py-40`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <Heading
            content={get(page, 'contactHeader.titleNode')}
            headingType="h2"
            style={tw`col-span-4  md:col-span-8 text-primary-500`}
          />
          <div
            css={tw`whitespace-pre flex flex-col col-span-4 space-y-8 mt-12 md:col-span-8 
            lg:(col-span-10) xl:(flex-row col-span-14 space-y-0 justify-between mt-24) xxl:(col-span-16)`}>
            {get(page, 'contactList').map((contact, i) => (
              <ContactCard
                key={i}
                label={contact.label}
                contactType={contact.contactType}
                image={contact.contactIcon}
                value={contact.valueText}
                subtext={contact.subTextNode}
                style={tw`flex-1`}
              />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.object.isRequired,
  }),
}

export default IndexPage

export const query = graphql`
  query IndexQuery($locale: String!) {
    index: datoCmsHkGiHomepage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
          url
        }
        imageMobile {
          format
          gatsbyImageData(layout: FULL_WIDTH)
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      statisticsCta {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      statistics {
        labelNode {
          childMarkdownRemark {
            html
          }
        }
        value
      }
      logosHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      logos {
        format
        url
      }
      formTitle
      formRequiredLabel
      formFields {
        fieldLabel
        fieldName
        fieldRequired
        fieldRequiredLabel
        fieldType
        fieldOptionsNode {
          childMarkdownRemark {
            html
          }
        }
      }
      formButtonLabel
      formSuccessNode {
        childMarkdownRemark {
          html
        }
      }
      faqHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      faqQuestion {
        identifier
        title
        date
        contentNode {
          childMarkdownRemark {
            html
          }
        }
        faq {
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            url
            format
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      faqButtonLabel
      contactHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactList {
        label
        contactType
        contactIcon {
          format
          url
        }
        value
        valueText
        subtextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactMap {
        url
        format
        gatsbyImageData(layout: FULL_WIDTH)
      }
      brokersHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      brokers {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        imageMobile {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
          subLinks {
            label
            link
            description
            subLinks {
              label
              link
            }
          }
        }
        callToActionSecond {
          label
          description
          link
          disabled
          subLinks {
            label
            link
            description
            subLinks {
              label
              link
            }
          }
          document {
            url
            filename
          }
        }
      }
      sliderLabel
      featuresHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      features {
        image {
          format
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          description
          link
          disabled
          subLinks {
            label
            link
            description
            subLinks {
              label
              link
            }
          }
          document {
            url
            filename
          }
        }
        callToActionSecond {
          label
          description
          link
          disabled
          subLinks {
            label
            link
            description
            subLinks {
              label
              link
            }
          }
          document {
            url
            filename
          }
        }
      }
      swipeLabel
      reachOutUsNode {
        childMarkdownRemark {
          html
        }
      }
      newsHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      news {
        typeOfArticle
        slug
        headline
        date
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      showAllNewsCta
      newsLink {
        label
        link
        disabled
      }
    }
  }
`
